/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import TopLayout from './src/layouts/topLayout';

require("prismjs/themes/prism-solarizedlight.css");
require("./src/layouts/code-block-title.css");

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};

