// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-de-js": () => import("./../src/templates/article.de.js" /* webpackChunkName: "component---src-templates-article-de-js" */),
  "component---src-templates-article-en-js": () => import("./../src/templates/article.en.js" /* webpackChunkName: "component---src-templates-article-en-js" */),
  "component---src-templates-article-it-js": () => import("./../src/templates/article.it.js" /* webpackChunkName: "component---src-templates-article-it-js" */),
  "component---src-templates-article-nl-js": () => import("./../src/templates/article.nl.js" /* webpackChunkName: "component---src-templates-article-nl-js" */),
  "component---src-templates-article-pt-js": () => import("./../src/templates/article.pt.js" /* webpackChunkName: "component---src-templates-article-pt-js" */),
  "component---src-templates-article-zh-js": () => import("./../src/templates/article.zh.js" /* webpackChunkName: "component---src-templates-article-zh-js" */),
  "component---src-templates-article-es-js": () => import("./../src/templates/article.es.js" /* webpackChunkName: "component---src-templates-article-es-js" */),
  "component---src-templates-article-fr-js": () => import("./../src/templates/article.fr.js" /* webpackChunkName: "component---src-templates-article-fr-js" */),
  "component---src-templates-article-ru-js": () => import("./../src/templates/article.ru.js" /* webpackChunkName: "component---src-templates-article-ru-js" */),
  "component---src-templates-article-ja-js": () => import("./../src/templates/article.ja.js" /* webpackChunkName: "component---src-templates-article-ja-js" */),
  "component---src-templates-tags-de-js": () => import("./../src/templates/tags.de.js" /* webpackChunkName: "component---src-templates-tags-de-js" */),
  "component---src-templates-tags-en-js": () => import("./../src/templates/tags.en.js" /* webpackChunkName: "component---src-templates-tags-en-js" */),
  "component---src-templates-tags-fr-js": () => import("./../src/templates/tags.fr.js" /* webpackChunkName: "component---src-templates-tags-fr-js" */),
  "component---src-templates-tags-it-js": () => import("./../src/templates/tags.it.js" /* webpackChunkName: "component---src-templates-tags-it-js" */),
  "component---src-templates-tags-es-js": () => import("./../src/templates/tags.es.js" /* webpackChunkName: "component---src-templates-tags-es-js" */),
  "component---src-templates-tags-nl-js": () => import("./../src/templates/tags.nl.js" /* webpackChunkName: "component---src-templates-tags-nl-js" */),
  "component---src-templates-tags-ja-js": () => import("./../src/templates/tags.ja.js" /* webpackChunkName: "component---src-templates-tags-ja-js" */),
  "component---src-templates-tags-ru-js": () => import("./../src/templates/tags.ru.js" /* webpackChunkName: "component---src-templates-tags-ru-js" */),
  "component---src-templates-tags-pt-js": () => import("./../src/templates/tags.pt.js" /* webpackChunkName: "component---src-templates-tags-pt-js" */),
  "component---src-templates-tags-zh-js": () => import("./../src/templates/tags.zh.js" /* webpackChunkName: "component---src-templates-tags-zh-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-de-js": () => import("./../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-fr-js": () => import("./../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-it-js": () => import("./../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-index-ja-js": () => import("./../src/pages/index.ja.js" /* webpackChunkName: "component---src-pages-index-ja-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-nl-js": () => import("./../src/pages/index.nl.js" /* webpackChunkName: "component---src-pages-index-nl-js" */),
  "component---src-pages-index-pt-js": () => import("./../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-index-ru-js": () => import("./../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-index-zh-js": () => import("./../src/pages/index.zh.js" /* webpackChunkName: "component---src-pages-index-zh-js" */)
}

